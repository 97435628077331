import React, { createContext, useContext, useEffect, useState } from 'react';
import { gatsbyNavigate } from '../navigateOverride';
import jwt_decode from 'jwt-decode';
import axios from 'axios';

// Hooks
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { AnalyticsContext } from '../../analytics/AnalyticsContext';
import useValidateSession from '../../hooks/useValidateSession';

// Utils
import { fetchTokens } from './authHelpers';
import { setAuthToken } from './setAuthToken';

// Types
import { IUser } from '../../@types';
import { IAuthContext, IAuthProvider, ITokenResponse } from './authTypes';

export const AuthContext = createContext<IAuthContext>({
  logout: () => {},
  loginInProgress: false,
  setLoginInProgress: () => {},
  user: null,
  isValidSession: false,
  contextToken: '',
});

interface ILocalAuthInfo {
  accessToken: string;
  idToken: string;
  createdTime: number;
  expirationTime: number;
  refreshToken: string;
}

export const AuthProvider = ({ authConfig, children }: IAuthProvider) => {
  //=============================================
  // Local Storage
  //=============================================
  const [localAuthInfo, setLocalAuthInfo] =
    useLocalStorage<ILocalAuthInfo | null>('jid', null);

  //=============================================
  // State
  //=============================================
  const [user, setUser] = useState<IUser | null>(null);
  const [loginInProgress, setLoginInProgress] = useState<boolean>(false);
  const [loginTracked, setLoginTracked] = useState<boolean>(false);
  const [contextToken, setContextToken] = useState('');

  const { analytics } = useContext(AnalyticsContext);
  const { isValidSession } = useValidateSession(user, logout);

  //=============================================
  // Handler Methods
  //=============================================

  // This endpoint invalidates dla cookies and authentication tokens
  async function handleSingleLogout() {
    if (user) {
      try {
        await axios({
          method: 'POST',
          url: `${process.env.GATSBY_IDM_API_URL}/auth/oauth/v2/logout/single`,
          headers: {
            Authorization: `Bearer ${user.access_token}`,
          },
        });
        console.log('Single Logout Successful');
      } catch (error: any) {
        console.log('Single Logout Error: ', error);
      }
    }
  }

  async function logout() {
    await handleSingleLogout();
    setLocalAuthInfo(null);
    setLoginInProgress(false);
    setUser(null);
    setContextToken('');

    if (analytics) {
      analytics.track('logout');
    }
  }

  async function handleTokenResponse(response: ITokenResponse) {
    setLocalAuthInfo({
      accessToken: response.access_token,
      idToken: response.id_token,
      createdTime: Date.now(),
      expirationTime: Date.now() + response.expires_in * 1000,
      refreshToken: response.refresh_token,
    });
    setAuthToken({
      accessToken: response.access_token,
      idToken: response.id_token,
    });
    const decodedIdJwt = jwt_decode(response.id_token);

    if (decodedIdJwt) {
      const updatedUserData = {
        ...decodedIdJwt,
        access_token: response.access_token,
      };
      setUser(updatedUserData as IUser);
    }

    setLoginInProgress(false);
  }

  //=============================================
  // Side Effects
  //=============================================
  useEffect(() => {
    if (localAuthInfo && analytics && user && !loginTracked) {
      const { auth_time } = user;
      analytics.track('loginStart', {
        opType: 'verifierAuth',
      });
      analytics.track('loginStop', {
        opSuccess: true,
        loginCompletedTs: auth_time,
      });
      setLoginTracked(true);
    }
  }, [localAuthInfo, analytics, user, loginTracked]);

  useEffect(() => {
    (async () => {
      const urlParams = new URLSearchParams(window.location.search);

      const code = urlParams.get('code');
      let state = urlParams.get('state');

      const stateObj = JSON.parse(state!) || { prevPath: '/' };

      // If the user is redirected back from Central Auth
      if (code) {
        // Navigate to previousPath that user was on before logging in
        gatsbyNavigate(stateObj.prevPath);

        setLoginInProgress(true);

        const tokens = await fetchTokens(authConfig);
        handleTokenResponse(tokens);

        // Call any postLogin function in authConfig
        if (authConfig?.postLogin) authConfig.postLogin();

        // If the user already has a token in local storage
      } else if (localAuthInfo) {
        // Check to see if the Access Token is expired
        const accessTokenIsExpired = Date.now() > localAuthInfo.expirationTime;

        // If the Access Token is expired, fetch a new Access Token using the Refresh Token
        if (accessTokenIsExpired) {
          // Currently getting an INVALID_THUMBPRINT_COOKIE error with new token
          // Temp logout until refresh token issue is resolved
          logout();

          // const newTokens = await fetchWithRefreshToken({
          //   authConfig,
          //   refreshToken: localAuthInfo.refreshToken,
          // });

          // // If the Refresh Token is expired, or has errors, return without setting user
          // if (newTokens.error) {
          //   logout();
          //   return;
          // }

          // setLocalAuthInfo({
          //   accessToken: newTokens.access_token,
          //   idToken: newTokens.id_token,
          //   createdTime: Date.now(),
          //   expirationTime: Date.now() + newTokens.expires_in * 1000,
          //   refreshToken: newTokens.refresh_token,
          // });
        } else {
          // Set Axios Global Headers
          setAuthToken({
            accessToken: localAuthInfo.accessToken,
            idToken: localAuthInfo.idToken,
          });

          // Set User
          const user: IUser | null = jwt_decode(localAuthInfo.idToken);
          if (user) {
            setUser({ ...user, access_token: localAuthInfo.accessToken });
          }
        }
      }
    })();
    // eslint-disable-next-line
  }, []);

  // Checks for a context_token passed by the source application
  useEffect(() => {
    const url = new URL(window.location.href);
    const contextTokenParam = url.searchParams.get('context_token');

    if (contextTokenParam) {
      // If it exists, set the context token in AuthContext provider
      setContextToken(contextTokenParam);
      // Remove the context_token parameter and direct the user to the page
      url.searchParams.delete('context_token');
      gatsbyNavigate(url.pathname, { replace: true });
    }
  }, []);

  //=============================================
  // Return
  //=============================================
  return (
    <AuthContext.Provider
      value={{
        logout,
        loginInProgress,
        setLoginInProgress,
        user,
        isValidSession,
        contextToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
