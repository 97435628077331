import { navigate } from 'gatsby';

// Reach-router's NavigateFn type is incorrectly interpreted via gatsby to only allow number as first argument, so overriding here
interface NavigateFn {
  (to: string, options?: NavigateOptions<{}>): Promise<void>;
  (to: number, options?: undefined): Promise<void>;
  (to: string | number, options?: NavigateOptions<{}>): Promise<void>;
}

interface NavigateOptions<TState> {
  state?: TState | undefined;
  replace?: boolean | undefined;
}

const gatsbyNavigate: NavigateFn = navigate as NavigateFn;


export { gatsbyNavigate };
