exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cast-tsx": () => import("./../../../src/pages/cast.tsx" /* webpackChunkName: "component---src-pages-cast-tsx" */),
  "component---src-pages-events-tsx": () => import("./../../../src/pages/events.tsx" /* webpackChunkName: "component---src-pages-events-tsx" */),
  "component---src-pages-get-started-x-tsx": () => import("./../../../src/pages/get-started-x.tsx" /* webpackChunkName: "component---src-pages-get-started-x-tsx" */),
  "component---src-pages-networks-tsx": () => import("./../../../src/pages/networks.tsx" /* webpackChunkName: "component---src-pages-networks-tsx" */),
  "component---src-pages-packages-tsx": () => import("./../../../src/pages/packages.tsx" /* webpackChunkName: "component---src-pages-packages-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-tv-apps-tsx": () => import("./../../../src/pages/tv-apps.tsx" /* webpackChunkName: "component---src-pages-tv-apps-tsx" */),
  "component---src-templates-custom-landing-page-custom-landing-page-tsx": () => import("./../../../src/templates/customLandingPage/customLandingPage.tsx" /* webpackChunkName: "component---src-templates-custom-landing-page-custom-landing-page-tsx" */),
  "component---src-templates-event-tsx": () => import("./../../../src/templates/event.tsx" /* webpackChunkName: "component---src-templates-event-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-movie-tsx": () => import("./../../../src/templates/movie.tsx" /* webpackChunkName: "component---src-templates-movie-tsx" */),
  "component---src-templates-movies-tsx": () => import("./../../../src/templates/movies.tsx" /* webpackChunkName: "component---src-templates-movies-tsx" */),
  "component---src-templates-network-network-categories-tsx": () => import("./../../../src/templates/network/networkCategories.tsx" /* webpackChunkName: "component---src-templates-network-network-categories-tsx" */),
  "component---src-templates-network-network-default-tsx": () => import("./../../../src/templates/network/networkDefault.tsx" /* webpackChunkName: "component---src-templates-network-network-default-tsx" */),
  "component---src-templates-network-network-originals-tsx": () => import("./../../../src/templates/network/networkOriginals.tsx" /* webpackChunkName: "component---src-templates-network-network-originals-tsx" */),
  "component---src-templates-package-package-tsx": () => import("./../../../src/templates/package/package.tsx" /* webpackChunkName: "component---src-templates-package-package-tsx" */),
  "component---src-templates-program-tsx": () => import("./../../../src/templates/program.tsx" /* webpackChunkName: "component---src-templates-program-tsx" */),
  "component---src-templates-series-tsx": () => import("./../../../src/templates/series.tsx" /* webpackChunkName: "component---src-templates-series-tsx" */),
  "component---src-templates-strapi-title-tsx": () => import("./../../../src/templates/strapiTitle.tsx" /* webpackChunkName: "component---src-templates-strapi-title-tsx" */),
  "component---src-templates-trailers-trailers-tsx": () => import("./../../../src/templates/trailers/trailers.tsx" /* webpackChunkName: "component---src-templates-trailers-trailers-tsx" */),
  "component---src-templates-trailers-trailers-watch-tsx": () => import("./../../../src/templates/trailers/trailersWatch.tsx" /* webpackChunkName: "component---src-templates-trailers-trailers-watch-tsx" */),
  "component---src-templates-tv-tsx": () => import("./../../../src/templates/tv.tsx" /* webpackChunkName: "component---src-templates-tv-tsx" */),
  "component---src-templates-xumo-xumo-tsx": () => import("./../../../src/templates/xumo/xumo.tsx" /* webpackChunkName: "component---src-templates-xumo-xumo-tsx" */)
}

